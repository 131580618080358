import $ from 'jquery';
import imagesLoaded from 'imagesloaded';

$(function() {
	'use strict';

	function handleNavSidebar() {
		function toggleNav() {
			$navSidebar.toggleClass(revealClass);
			$navTrigger.toggleClass(revealClass);
			$navContainer.toggleClass(revealClass);
		}

		var $navSidebar = $('.js-nav'),
			$navTrigger = $('.js-nav-trigger'),
			$navContainer = $('.header__nav-link'),
			revealClass = 'active';

		$body.on('click', '.js-nav-trigger', toggleNav);
		
		$win.on('keydown', function(e) {
			var keyCode = e.keyCode || e.which;
			
			if (keyCode == 9) {
				 e.preventDefault();
				toggleNav(); 
			}
		});
	}

	const Announcements = {
		$messages: $('.js-announcement'),

		init: function () {
			// Show the first announcement only to begin with.
			// Show it after a short delay so the rest of the page should be visible...
			setTimeout(function () {
				Announcements.$messages.eq(0).addClass('visible');
			}, 2000);

			// When a close link is clicked:
			// – Hide the message
			// – Create a cookie for that announcement using messageid data attr
			// – show the next message if there is one
			$body.on('click', '.js-announcement-close', function (e) {
				e.preventDefault();

				var $close = $(this),
					d = new Date();

				// Hide this message
				$close.closest('.js-announcement').removeClass('visible');

				if (document.cookie.indexOf("LS-cookies-allow=") >= 0) {

				// Create a cookie for this announcement to expire 2 weeks from now
				d.setTime(d.getTime() + (14 * 24 * 60 * 60 * 1000));

				document.cookie = $close.data('messageid') + "=true; expires=" + d.toUTCString() + "; path=/";

				}

				// Show the next announcement if there is one
				Announcements.$messages = Announcements.$messages.not(':eq(0)');
				Announcements.$messages.eq(0).addClass('visible');
			});
		}
	};

	const Cookies = {
		$cookienotice: $('.js-cookie-notice'),

		init: function () {
			 setTimeout(function () {
                    Cookies.$cookienotice.eq(0).addClass('visible');
                }, 4000);

			$body.on('click', '.js-cookie-agree', function (e) {
				e.preventDefault();

				var d = new Date();

				$('.js-cookie-notice').removeClass('visible');

				d.setTime(d.getTime() + (3 * 30 * 24 * 60 * 60 * 1000));

				document.cookie = "LS-cookies-allow=true; expires=" + d.toUTCString() + "; path=/";
			});

			$body.on('click', '.js-cookie-disagree', function (e) {
				e.preventDefault();

				$('.js-cookie-notice').removeClass('visible');
			})
		}
	};

	function pageHide() {
		$('.js-page-container').addClass('loading');
		$('.global__revealer').addClass('cover');
		$('.global__page-container').addClass('hide');
	}

	function pageReveal() {
		var	$talks = $('.resources__item');

		imagesLoaded($body, () => {
			$('.js-page-container').removeClass('loading');
			$('.global__revealer').removeClass('cover');
			$('.global__page-container').removeClass('hide');

			if ($talks.length) {
				$('.js-post-container').removeClass('loading');
				$('.load-in').addClass('loaded');
			}

			pageLoaded = true;
		});
	}

	function newPageLoad(e) {
		var $target = $(e.currentTarget),
			href = $target.attr('href'),
			check = window.location.href;

		if (window.location.hostname === this.hostname) {
			e.preventDefault();

			if (href == check) {
				return;
			}

			pageReloader(href);
		}
	}

	function pageReloader(href, disablePushState) {
		$body.removeClass('lock-scroll');

		pageHide();

		setTimeout(function(){
			LightboxVideos = null;

			if (!disablePushState) {
				// Update the browser's address bar
				window.history.pushState({ url: href }, null, href);
			}

			$.ajax(href, {
				dataType : 'html',
				success : function (response) {
					reloadPage(response);
				},
				complete: function () {
					setTimeout(function(){
						document.body.scrollTop = document.documentElement.scrollTop = 0;

						handleLightboxVideos();

						pageReloadFunctions(); }, 250);
					}
				}
			);
		}, 250);
	}

	function reloadPage (html) {
		// Update the <title> tag
		document.title = $(html).filter('title').text();

		// Get the new body class
		var parser = new DOMParser(),
			doc = parser.parseFromString(html, "text/html"),
			docClass = doc.body.getAttribute('class');

		parser = doc = null;

		// Update the .projects DOM and Body Class
		document.body.className = docClass;

		var $html = $('<div />').append(html);

		$('.js-page-reload').html($html.find('.js-page-reload').html());
	}

	function handleTouchEvents() {
		if (projectTouch.length) {
			var pageLinks = document.querySelectorAll('.uni-touch');

			[...projectTouch].forEach(el => {
				el.addEventListener("touchend", e => {
					e.preventDefault();
					
					if (dragging == true) {
						return false;
					}

					if (el.classList.contains('touched')) {
						if (el.classList.contains('new-tab')) {
							window.open(el.getAttribute('href'), '_blank');
						} else {
							// Navigate to page
							if (window.location.hostname === this.hostname) {
								pageReloader(el.getAttribute('href'));
							} else {
								window.location = el.getAttribute('href');
							}
						}
					} else {
						// Apply touched class
						el.classList.add("touched");

						el.parentElement.classList.add("touched");

						// Remove 'touched' class from other elements
						const touched = [...pageLinks].filter(
							item => item.classList.contains("touched")
						);

						touched.forEach(item => {
							if (item !== el) {
								item.classList.remove("touched");
								item.parentElement.classList.remove("touched");
							}
						});
					}
				});
			});
		}
	}

	function teamReadMore(e) {
		e.preventDefault();
		const trigger = e.target,
			container = trigger.previousElementSibling;
		
		if (container.classList.contains('show')){
			trigger.innerHTML = 'Show More';
		} else {
			trigger.innerHTML = 'Show Less';
		}

		container.classList.toggle('show');
	}

	function loadMorePosts(e) {
		var total,
			pagelimit,
			cat,
			offset,
			postOutput,
			count,
			page,
			number;

		e.preventDefault();

		LightboxVideos = null;

		var filler = '<button class="overlay__close-link" title="Close video"><span class="button-touchpoint-helper"><span class="sprite icon icon--close-lrg"></span></span></button><div style="padding: 0 0 56.25%; height:0; overflow:visible; position: relative;"></div>';

		$('.js-hero-video-container').html(filler);

		$('.js-videos-container').removeClass('all-videos-ready');

		var $filter = $(e.currentTarget);
			total = parseInt(this.dataset.postcount),
			pagelimit = parseInt(this.dataset.pagelimit),
			cat;

		if (("category" in this.dataset)) {
			cat = this.dataset.category;
		} else {
			cat = null;
		}

		offset = $('.resources__item').length;

		postsAjax(offset, total, cat);

		function postsAjax (offset, total, cat) {
			$.get('/ajax/sermons', { offset:offset, cat:cat }, function (data) {
        		postOutput = $('.js-post-container');

				postOutput.append(data);
			}).done(function() {
				count = $('.resources__item').length;

				if (count === total) {
					$('.js-load-more').fadeOut(200);
				}

				imagesLoaded( '.js-post-container', function() {
					$('.load-in').addClass('loaded');
				});

				handleLightboxVideos();
			});
		}
	}

	function resourcesDropdown(e) {
		var href = e;

		resourcesFilter(href)
	}

	function resourcesLink(e) {
		e.preventDefault();

    	var $filter = $(e.currentTarget),
			href = $filter.attr('href');

		resourcesFilter(href);
	}

	function resourcesFilter(href) {
		$('.js-post-container').addClass('loading');

		$('.load-in').removeClass('loaded');

		LightboxVideos = null;

		var filler = '<button class="overlay__close-link" title="Close video"><span class="button-touchpoint-helper"><span class="sprite icon icon--close-lrg"></span></span></button><div style="padding: 0 0 56.25%; height:0; overflow:visible; position: relative;"></div>';

		$('.js-hero-video-container').html(filler);

		$('.js-videos-container').removeClass('all-videos-ready');

		if (historySupport) {
        	// Update the browser's address bar
        	window.history.pushState(null, null, href);
		}

		$.ajax(href, {
			dataType : 'html',
        	success : function (response) {
				refreshProjects(response);
			},
			complete: function () {
				setTimeout(function(){
					$('.js-post-container').removeClass('loading');

					$('.load-in').addClass('loaded');
				}, 150);

				$mobileFilters = $('.js-mobile-filter');

				filterReloadMobile($mobileFilters);

				handleLightboxVideos();
			}
		});

		function refreshProjects (html) {
			// Update the <title> tag
			document.title = $(html).filter('title').text();

			// Update the .projects DOM element with new HTML
			var $html = $('<div />').append(html);

			$('.js-resources-feed').html($html.find('.js-resources-feed').html());
		}
	}

    // The Slide class.
   	function Slide(el) {
		this.DOM = {el: el};

		// The image conteiner.
		this.DOM.imgWrap = this.DOM.el.querySelector('.slide__img-wrap');

		// The revealer element (the element with the same color of the body that covers the image).
		this.DOM.revealer = this.DOM.imgWrap.querySelector('.slide__img-reveal');

		// The title element.
		this.DOM.title = this.DOM.el.querySelector('.slide__title');

		// The slide´s number element
		this.DOM.number = this.DOM.el.querySelector('.slide__number');

		// Some config values.
		this.config = {
			animation: {
				duration: 0.6,
				ease: Expo.easeOut
			}
		};
	}

	// Sets the current class.
	Slide.prototype.setCurrent = function(isCurrent = true) {
		this.DOM.el.classList[isCurrent ? 'add' : 'remove']('slide--current');
	};

	// Hide the slide.
	Slide.prototype.hide = function(direction) {
		return this.toggle('hide', direction);
	};

	// Show the slide.
	Slide.prototype.show = function(direction)  {
		return this.toggle('show', direction);
	};

	// Show/Hide the slide.
	Slide.prototype.toggle = function(action, direction) {
		// Hide/Show revealer on top of the image and move the image, title and number.
		return new Promise((resolve, reject) => {

			let revealerOpts = {
				delay: action === 'hide' ? 0 : this.config.animation.duration/2,
				ease: this.config.animation.ease,
				onComplete: resolve
			};

			const commonOpts = {
				delay: action === 'hide' ? 0 : this.config.animation.duration/2,
				ease: this.config.animation.ease,
				opacity: action === 'hide' ? 0 : 1
			};

			let imgOpts = Utilities.assign({},commonOpts);
			let numberOpts = Utilities.assign({},commonOpts);
			let titleOpts = Utilities.assign({},commonOpts);

			revealerOpts.startAt = action === 'hide' ? {x: direction === 'left' ? '-100%' : '100%', y:'0%'} : {x: '0%', y:'0%'};
			revealerOpts.x = action === 'hide' ? '0%' : direction === 'left' ? '100%' : '-100%';
			imgOpts.startAt = action === 'show' ? {opacity: 0, x: direction === 'left' ? '-20%' : '20%'} : {};
			imgOpts.x = action === 'hide' ? direction === 'left' ? '20%' : '-20%' : '0%';
			titleOpts.startAt = action === 'show' ? {opacity: 1, scale: 1, x: direction === 'left' ? '-200%' : '200%'} : {};
			titleOpts.x = action === 'hide' ? direction === 'left' ? '200%' : '-200%' : '0%';
			titleOpts.scale = action === 'hide' ? 1 : 1;
			numberOpts.startAt = action === 'show' ? {opacity: 1, x: direction === 'left' ? '-50%' : '50%'} : {};
			numberOpts.x = action === 'hide' ? direction === 'left' ? '50%' : '-50%' : '0%';

			// Toggling the revealer.
			TweenMax.to(this.DOM.revealer, this.config.animation.duration, revealerOpts);
			// Moving & fading the image (wrappper).
			TweenMax.to(this.DOM.imgWrap, this.config.animation.duration, imgOpts);
			// Moving & fading the title and number.
			TweenMax.to(this.DOM.title, this.config.animation.duration*1.5, titleOpts);
			TweenMax.to(this.DOM.number, this.config.animation.duration, numberOpts);
		});
	};

    // The Slideshow class.
    function Slideshow(el) {
		this.DOM = {el: el};
		this.$el = el;
		this.globalFooter = $('footer');
		this.globalHeader = $('header');
		this.slideNav = $('.slidenav');

		// Navigation controls (prev, next and preview ctrls)
		this.controls = [];
		Array.from(this.DOM.el.querySelectorAll('.slidenav__item')).forEach(controlEl => this.controls.push(controlEl));

		// The slides.
		this.slides = [];
		Array.from(this.DOM.el.querySelectorAll('.slide')).forEach(slideEl => this.slides.push(new Slide(slideEl)));

		// The total number of slides.
		this.slidesTotal = this.slides.length;

		// Current slide position.
		this.current = 0;
		this.timeCount = null;

		// Check whether first slide is inverted.
		var $current = this.slides[this.current].DOM.el;
		
		if ($current.classList.contains('invert-text')) { 
			this.invertSlideShow(true);	
		}

		// initialize the slideshow.
		this.init();
	}

	// init: set the current slide and initialize some events..
	Slideshow.prototype.init = function() {
		this.slides[this.current].setCurrent();
		this.controls[this.current].classList.add('active');
		this.initEvents();
		this.initTouchEvents();
		this.timer();
		let vh = window.innerHeight * 0.01;

		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};

	Slideshow.prototype.timer = function() {
		var $slider = this;

		if (this.$el === "undefined") {
			var delay = 6000; // IE10 Fallback
		} else {
			var delay = this.$el.dataset.delay * 1000;
		}
		setInterval(function(){
			if ($slider.isPaused ) return;
			if (pageLoaded != true) return;
			$slider.swipe('right', false);
		}, delay);
	}

	Slideshow.prototype.initTouchEvents = function() {
		var xDown = null;
		var yDown = null;

		$win.on('touchstart', function(e) {
			xDown = e.originalEvent.touches[0].pageX;
			yDown = e.originalEvent.touches[0].pageY;
		}.bind(this));
		
		$win.on('touchmove', function(e) {
			if ( ! xDown ) {
				return;
			}

			var xUp = e.touches[0].clientX;
			var yUp = e.touches[0].clientY;

			var xDiff = xDown - xUp;
			var yDiff = yDown - yUp;

			if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
				if ( xDiff > 0 ) {
					this.swipe('right', true);
				} else {
					this.swipe('left', true);
				}
			}

			/* reset values */
			xDown = null;
			yDown = null;
		}.bind(this));
	};

	Slideshow.prototype.initEvents = function() {
		this.controls.forEach(this.addEventListener, this);

		$win.on('keydown', function(e) {
			switch (event.keyCode) {
				case 37:
					this.swipe('left', true)
					break;
				case 39:
					this.swipe('right', true);
					break;
			}
		}.bind(this));
	};

	Slideshow.prototype.addEventListener = function(control, index) {
		control.addEventListener('click', () => this.slideMove(index));
	};

	Slideshow.prototype.invertSlideShow = function(invert) {
		this.globalHeader[0].classList[invert ? 'add' : 'remove']('invert');
		this.globalFooter[0].classList[invert ? 'add' : 'remove']('invert');
		this.slideNav[0].classList[invert ? 'add' : 'remove']('invert');
	}

	Slideshow.prototype.slideMove = function(number) {
		if ( this.isAnimating ) return;

		this.isAnimating = true;
		this.isPaused = true;

		var getSlider = this;

		clearTimeout(this.timeCount);
		this.timeCount = setTimeout(function(){ getSlider.isPaused = false },12000);

		const nextSlidePos = number;

		if ( this.current === number ) { this.isAnimating = false; return; }

		const direction = this.current < number ? 'right' : 'left';

		// Get current and next slides
		var $current = this.slides[this.current].DOM.el;
		var $next = this.slides[nextSlidePos].DOM.el;

		// If current slide has a video, pause it
		if ($current.classList.contains('with-video')) {
			var $video = $current.querySelectorAll('video');
			if ($video.length) {
				$video[0].pause();
			}
		}

		// If the next slide has a video, play it
		if ($next.classList.contains('with-video')) {
			var $video = $next.querySelectorAll('video');
			if ($video.length) {
				$video[0].play();
			}
		}

		// If next slide inverts colour, invert (or switch back)
		if ($next.classList.contains('invert-text')) {
			this.invertSlideShow(true);
		} else {
			this.invertSlideShow(false);
		}

		Promise.all([this.slides[this.current].hide(direction), this.slides[nextSlidePos].show(direction)])
			.then(() => {
				// Update current.
				this.slides[this.current].setCurrent(false);
				this.controls[this.current].classList.remove('active');
				this.current = nextSlidePos;
				this.isAnimating = false;
				this.slides[this.current].setCurrent();
				this.controls[this.current].classList.add('active');

			});
	};

	Slideshow.prototype.toggleNavCtrls = function(action) {
		TweenMax.to([this.DOM.prevCtrl, this.DOM.nextCtrl], 0.5, {
			ease: 'Expo.easeOut',
			opacity: action === 'enter' ? 0 : 1,
			onStart: () => this.DOM.prevCtrl.style.pointerEvents = this.DOM.nextCtrl.style.pointerEvents = action === 'enter' ? 'none' : 'auto'
		});
	};
	
	// swipe the slideshow.
    Slideshow.prototype.swipe = function(direction, manual) {
		// If animating return.
		if ( this.isAnimating ) {
			return;
		}
		
		this.isAnimating = true;

		if (manual == true) {
			this.isPaused = true;

			var getSlider = this;
			clearTimeout(this.timeCount);
			this.timeCount = setTimeout(function(){ getSlider.isPaused = false },12000);
		}

		const nextSlidePos = direction === 'right' ?
				this.current < this.slidesTotal-1 ? this.current+1 : 0 :
				this.current > 0 ? this.current-1 : this.slidesTotal-1;


		// Get current and next slides
		var $current = this.slides[this.current].DOM.el;
		var $next = this.slides[nextSlidePos].DOM.el;

		// If current slide has a video, pause it
		if ($current.classList.contains('with-video')) {
			var $video = $current.querySelectorAll('video');
			
			if ($video.length) {
				$video[0].pause();
			}
		}

		// If the next slide has a video, play it
		if ($next.classList.contains('with-video')) {
			var $video = $next.querySelectorAll('video');
			
			if ($video.length) {
				$video[0].play();
			}
		}

		// If next slide inverts colour, invert (or switch back)
		if ($next.classList.contains('invert-text')) {
			this.invertSlideShow(true);
		} else {
			this.invertSlideShow(false);
		}

		Promise.all([this.slides[this.current].hide(direction), this.slides[nextSlidePos].show(direction)])
			.then(() => {
				// Update current.
				this.slides[this.current].setCurrent(false);
				this.controls[this.current].classList.remove('active');
				this.current = nextSlidePos;
				this.isAnimating = false;
				this.slides[this.current].setCurrent();
				this.controls[this.current].classList.add('active');
			});
	};
	function WorkshopItem($item) {
		this.$item = $item;
		this.$movingContents = $item.find('.js-moving-content');
		this.layer = parseInt(this.$item.data('layer'));

		// If this layer is 0, this item will just move naturally
		this.naturalMovement = this.layer === 0;
	}

	// The speed factor applied to the calculation for movement. Note that this should never go
	// above or equal to (1 / [bottom-layer-number]) because then that layer would never
	// come into view
	WorkshopItem.prototype.speedFactor = 0.125;
	WorkshopItem.prototype.cssKeys = ['-webkit-transform', 'transform'];

	WorkshopItem.prototype.updateMaths = function() {
		// If we are moving naturally, we don't need to do anything
		if (this.naturalMovement) {
			return;
		}

		var offset = this.$item.offset().top,
		// The scroll position at which this item would naturally become visible, which we will use
		// as parallax 'zero'
			parallaxZero = offset - winH;

		// For items which appear 'above the fold', make sure they start in the correct place
		if (offset < winH) {
			parallaxZero = 0;
		}

		// Assign to the item
		this.parallaxZero = parallaxZero;
	};

	WorkshopItem.prototype.move = function() {
		// If we are moving naturally, we don't need to do anything
		if (this.naturalMovement) {
			return;
		}

		// How far we are into this item's movement
		var diff = winScrollT - this.parallaxZero,
		// Round the diff multiplied by the layer multiplied by the speed factor
			transformVal = Math.round(diff * this.layer * this.speedFactor);

		// We need to invert as moving 'quicker' involves a negative translate
		transformVal = transformVal * -1;

		this.$movingContents.css(this.buildCSS(transformVal));
	};

	/*
		Generates a CSS object to feed into jQuery's .css() method
	*/
	WorkshopItem.prototype.buildCSS = function(val) {
		var transform = 'translate3d(0, ' + val + 'px, 0)',
			css = {};

		this.cssKeys.forEach(function(key) {
			css[key] = transform;
		});

		return css;
	};

	/*
		Parents the individual Workshop Items.
		onResize / onScroll is handled at this level
	*/
	function WorkshopHandler($workshop) {
		var $initialItems = $workshop.find('.js-workshop-item');

		this.$workshop = $workshop;

		this.items = [];

		// This was performing super badly on the Windows Phone.
		// this.noMove = browserDetection.windowsPhone;

		this.createItems($initialItems);

		this.updateAndMove();
	}

	WorkshopHandler.prototype.workshopClass = 'js-workshop';

	WorkshopHandler.prototype.onScroll = function() {
		this.move();
	};

	WorkshopHandler.prototype.onResize = function() {
		this.updateAndMove();
	};

	WorkshopHandler.prototype.createItems = function($items) {
		$items.each(function(i) {
			this.items.push(new WorkshopItem($items.eq(i)));
		}.bind(this));
	};

	WorkshopHandler.prototype.updateAndMove = function() {
		this.updateMaths();

		this.move();
	};

	WorkshopHandler.prototype.updateMaths = function() {
		if (this.noMove) {
			return;
		}

		this.items.forEach(function(item) {
			// Individual item calculations are handled at the item level
			item.updateMaths();
		});
	};

	WorkshopHandler.prototype.move = function() {
		if (this.noMove) {
			return;
		}

		this.items.forEach(function(item) {
			// Individual item calculations are handled at the item level
			item.move();
		});
	};

	function Overlay($el) {
		// Assign the element to this object so we can use it in the future if needs be
		this.$el = $el;
		// Save a reference to the contents
		this.$contents = this.$el.find('.js-overlay-contents');

		this.setUpListeners();
	}

	Overlay.prototype.revealClass = 'reveal';
	Overlay.prototype.lightThemeClass = 'overlay--theme-light';

	Overlay.prototype.setUpListeners = function() {
		this.$el
			// On clicking the overlay directly, close it
			.on('click', this.closeOverlay.bind(this))

			// On touchmove on the overlay, don't allow it
			// so that we can't scroll the page on mobile devices
			.on('touchmove', function(e) {
				e.preventDefault();
			})

			// On transition end
			.on('transitionend', function() {
				// If this has been revealed
				if (this.$el.hasClass(this.revealClass)) {
					this.preventScroll();
				}
			}.bind(this));

		// On clicking the contents, don't close the overlay
		this.$contents.on('click', '> iframe', function(e) {
			e.stopPropagation();
		});

		// On keydown
		$win.on('keydown', function(e) {
			// If this is the escape key, close the overlay
			if (e.which === 27) {
				this.closeOverlay();
			}
		}.bind(this));
	};

	// Showing the overlay
	Overlay.prototype.showOverlay = function() {
		// If there is an onBeforeShow hook, run this
		if (typeof this.onBeforeShow === 'function') {
			this.onBeforeShow();
		}

		// Show the overlay
		this.$el.addClass(this.revealClass);
	};

	// Closing the overlay
	Overlay.prototype.closeOverlay = function() {
		// If there is an onBeforeClose hook, run this
		if (typeof this.onBeforeClose === 'function') {
			this.onBeforeClose();
		}

		this.enableScroll();

		// Hide the overlay
		this.fadeOut();
	};

	// Just manages the visuals - should normally only be called
	// from this.closeOverlay();
	Overlay.prototype.fadeOut = function() {
		this.$el.removeClass(this.revealClass);
	};

	Overlay.prototype.preventScroll = function() {
		$body.css('overflow', 'hidden');
	};

	Overlay.prototype.enableScroll = function() {
		$body.css('overflow', '');
	};

	Overlay.prototype.useLightTheme = function() {
		this.$el.addClass(this.lightThemeClass);
	};

	Overlay.prototype.resetTheme = function() {
		this.$el.removeClass(this.lightThemeClass);
	};
	function InlineVideo($video, $body) {
		this.$body = $body;
		this.videoCount = [];
		this.$video = $video;
		this.$container = this.$video.parent();
		this.video = $video[0];
		this.autoplays = $video.hasClass('js-autoplay');

		if (this.autoplays) {
			this.autoplayTargets = {};
		}

		this.getSource();
		this.setUpListeners();
	}

	InlineVideo.prototype.getSource = function() {
		var $source = this.$video.children();
			$.post('/scripts/get-vid.php', { videoId: $source.data('id') }, function(returnData) {
			// Apply the type to our player
			$source.attr('type', returnData.type);
			// Apply the src to our player
			$source.attr('src', returnData.link);

			// Load the sources into the video
			this.video.load();

			// If we are already ready to play, play it now
			if (this.video.readyState > 3) {
				if (this.autoplays) {
				this.video.play;
				}
			// Otherwise...
			} else {
				this.$video.one('canplaythrough', this.onVideoReady.bind(this));
			}
		}.bind(this), 'json');
	};

	InlineVideo.prototype.setUpListeners = function() {
		this.$video
			// When it is actually playing (to account for browsers in which autoplay doesn't work)
			.on('playing', this.onVideoPlaying.bind(this));
	};

	InlineVideo.prototype.onVideoReady = function() {
		this.isReady = true;
		
		if (this.autoplays) {
			this.video.play();
		}
	};

	InlineVideo.prototype.playVideo = function() {
		this.video.play();
	};

	InlineVideo.prototype.onVideoPlaying = function() {
		this.$container.addClass('playing');
	};

	InlineVideo.prototype.addUpVideos = function() {
		pageVideoCount++;
		if (pageVideoCount === globalVideoCount) {}
	}

	InlineVideo.prototype.pauseVideo = function(dontUnreveal) {
		this.video.pause();

		if (dontUnreveal !== true) {
			this.unrevealVideo();
		}

		this.$container.removeClass('playing');
	};
	function InlineVideosHandler($container) {
		this.inlineVideos = [];
		this.$container = $container;
		this.setUpVideos($container);
	}

	InlineVideosHandler.prototype.setUpVideos = function($container) {
		var $handler = this;
		
		globalVideoCount = $('.js-inline-video').length;
		
		$('.js-inline-video').each(function() {
			// Create a new inline video
			$handler.inlineVideos.push(new InlineVideo($(this), $container));
		});
	}

	InlineVideosHandler.prototype.pauseAllVideos = function() {
		this.inlineVideos.forEach(function(video) {
			video.pauseVideo();
		});
	};

	var Utilities = {
        events: [],

		assign: function(target, source) {
		  var result = {}

		  for (var i in target) result[i] = target[i]
		  for (var i in source) result[i] = source[i]

		  return result
		},

        addEvent: function(event, el, callback) {
            let i = 0,
                j = 0,
                addNode = true,
                addEvent = true;

            for (i; i < Utilities.events.length; i++) {
                // Check for presence of this element already
                if (Utilities.events[i].node === el) {
                    addNode = false;

                    // Already setup. Do we already have this event too?
                    for (j; j < Utilities.events[i].listeners; j++) {
                        if (Utilities.events[i].listeners[j].type === event) {
                            addEvent = false;
                        }
                    }

                    // If addEvent is still true by here we need to add it to the
                    // existing node object...
                    Utilities.events[i].listeners.push({
                        type: event,
                        func: callback.toString(),
                    });
                }
            }

            if (addNode) {
                // Record the node and event so we can look it up later if necessary
                Utilities.events.push({
                    node: el,
                    listeners: [
                        {
                            type: event,
                            func: callback.toString(),
                        },
                    ],
                });
            }

            // Attach the event
            el.addEventListener(event, callback);
        },

        forEach: function(array, callback, scope) {
            for (var i = 0; i < array.length; i++) {
                callback.call(scope, array[i], i);
            }
        }
    }

	function handleLightboxVideos() {
		LightboxVideos = {
			videoReferences : document.querySelectorAll('.js-play-video'),
					pageLinks : document.querySelectorAll('.uni-touch'),
			videoArray      : [],
			videosContainer : document.querySelector('.js-videos-container'),
					videosPlacement : document.querySelector('.js-hero-video-container'),
			aspectRatio     : 9 / 16,
			videosReady     : 0,

			init: function () {

				// Get all the videos ready
				this.load();

				// Pause/Close/Hide video events
				Utilities.addEvent('click', LightboxVideos.videosContainer, LightboxVideos.closeVideos);

				Utilities.addEvent('keydown', window, e => {
					var keyCode = e.keyCode;

					// If this is escape or enter, close the video
					if (keyCode === 27 || keyCode === 13) {
						LightboxVideos.closeVideos();
					}
				});
			},

			resizeVideos: function () {
				// Resize all videos...
				var i = 0,
					video;

				for (i; i < this.videoArray.length; i++) {
					video = document.getElementById('video_' + this.videoArray[i].videoId);

					if (typeof this.videoArray[i].vimeoPlayer !== 'undefined') {
						video = video.querySelector('iframe');
					}

					if (video.style.display === 'block') {
						let videoStyles = window.getComputedStyle(video, null),
							videoWidth = parseInt(videoStyles.getPropertyValue('width'), 10);

						video.height = videoWidth * this.aspectRatio;
					}
				}
			},

			// Clone the video associated with each play link into the global videos container
			load: function () {
				var t;

				Utilities.forEach(this.videoReferences, (el, i) => {
					var videoId = (el.dataset.videoid) ? el.dataset.videoid : i.toString(),
						video = document.createElement('div'),
						youtubePlayer,
						vimeoPlayer,
						clonedVideoEmbed;

					// If this is not a direct vimeoId video initiate the player
					if (el.classList.contains('js-embed-video')) {
						const container = Utilities.findParentByClass(el, 'page-section__image-container');

						// Clone the video embed into the new video node we've just created
						clonedVideoEmbed = container.querySelector('.js-video-embed iframe').cloneNode(true);

						clonedVideoEmbed.id = "video_" + videoId;

						// Add this ID to the play link
						el.dataset.videoid = videoId;

						LightboxVideos.videosPlacement.appendChild(clonedVideoEmbed);

						LightboxVideos.videoPlayerReady(el);
					} else {
						//  Add unique ID to this video element
						video.id = "video_" + videoId;

						// Save video DOM element to global videos container
						LightboxVideos.videosPlacement.appendChild(video);

						if (!isNaN(parseFloat(videoId)) && isFinite(videoId)) {
							vimeoPlayer = new Vimeo.Player(video, {
								width: 1280,
								id: videoId
							});

							vimeoPlayer.ready().then(LightboxVideos.videoPlayerReady(el));
						} else {
							// YouTube player
							youtubePlayer = new YT.Player(video, {
								width: '1280',
								height: '720',
								videoId: videoId,
								events: {
									'onReady': LightboxVideos.youtubePlayerReady
								}
							});

							youtubePlayer.trigger = el;
						}
					}

					LightboxVideos.videoArray.push({
						videoId: videoId,
						vimeoPlayer: vimeoPlayer,
						youtubePlayer: youtubePlayer,
						otherPlayer: clonedVideoEmbed
					});
				});

				// When there all the videos are 'ready' force hide the container again
				t = setInterval(function () {
					if (LightboxVideos.videosReady === LightboxVideos.videoReferences.length) {
						clearInterval(t);

						LightboxVideos.videosContainer.classList.add('all-videos-ready');
					}
				}, 25);
			},

			youtubePlayerReady: function (event) {
				LightboxVideos.videoPlayerReady(event.target.trigger);
			},

			videoPlayerReady: function (el) {
				// Add click listener to this play link
				Utilities.addEvent('click', el, LightboxVideos.playVideo);
				Utilities.addEvent('touchend', el, LightboxVideos.checkTouch);

				// Add video-ready class to this play link
				el.classList.add('video-ready');

				LightboxVideos.videosReady++;
			},

			checkTouch: function(e) {
				e.preventDefault();

				if (dragging == true) {
					return false;
				}

				var el = e.currentTarget;

				if (el.classList.contains('touched')) {
					LightboxVideos.playVideo(e);
				} else {
						// Apply touched class
						el.classList.add("touched");
						el.parentElement.classList.add("touched");

						// Remove 'touched' class from other elements
						const touched = [...LightboxVideos.pageLinks].filter(
							item => item.classList.contains("touched")
						);

						touched.forEach(item => {
							if (item !== el) {
								item.classList.remove("touched");
								item.parentElement.classList.remove("touched");
							}
						});
					}

			},

			// Show overlay and play video
			playVideo: function (e) {
				e.preventDefault();
				var $this = e.target;

				var videoObj = LightboxVideos.videoArray.filter(obj => {
						return obj.videoId === $this.dataset.videoid;
					}),
					video = document.getElementById('video_' + $this.dataset.videoid);

				// Make sure all videos are hidden to start with
				let iframes = LightboxVideos.videosContainer.querySelectorAll('iframe');

				Utilities.forEach(iframes, el => {
					el.style.display = 'none';
				});

				// Show and fadeIn video container
				LightboxVideos.videosContainer.style.display = 'block';

				setTimeout(function () {
					LightboxVideos.videosContainer.classList.add('visible');
				}, 250);

				// If a Vimeo instance we can play the video immediately
				if (typeof videoObj[0].vimeoPlayer !== 'undefined') {
					video = video.querySelector('iframe');
				}

				video.style.display = 'block';

				let videoStyles = window.getComputedStyle(video, null),
					videoWidth = parseInt(videoStyles.getPropertyValue('width'), 10);

				video.height = videoWidth * LightboxVideos.aspectRatio;

				if (typeof videoObj[0].vimeoPlayer !== 'undefined') {
					videoObj[0].vimeoPlayer.play();
				} else if (typeof videoObj[0].youtubePlayer !== 'undefined') {
					videoObj[0].youtubePlayer.playVideo();
				}
			},

			// Pause videos and hide page overlay
			closeVideos: function () {
				var i = 0;

				// Pause every video just in case
				for (i; i < LightboxVideos.videoArray.length; i++) {
					if (typeof LightboxVideos.videoArray[i].vimeoPlayer !== 'undefined') {
						LightboxVideos.videoArray[i].vimeoPlayer.pause();
					} else if (typeof LightboxVideos.videoArray[i].youtubePlayer !== 'undefined') {
						LightboxVideos.videoArray[i].youtubePlayer.pauseVideo();
					} else {
						// As a last ditch attempt let's try and force a pause command through
						LightboxVideos.videoArray[i].otherPlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
					}
				}

				// Fadeout overlay
				LightboxVideos.videosContainer.classList.remove('visible');

				// Set overlay to display:none after fadeout
				setTimeout(function () {
					LightboxVideos.videosContainer.style.display = "none";
				}, 500); // duration should be >= CSS transition time
			}
		};

		LightboxVideos.init();
	}

	var $win = $(window),
		$body = $('body'),
		$workshop = $('.js-workshop'),
		basepath = $body.data('basepath'),
		$video = $('.js-inline-video'),
		$widetext = $('.page__wide-intro-text.overlap'),
		$dummy = $('.wide-intro__dummy'),
		slidetest = $('.slide'),
		projectTouch = $('.js-touch-hover-state'),
		rootPath = $body.data('rootpath'),
		globalVideoCount = 0,
		pageVideoCount = 0,
		pageLoaded = false,
		dragging = false,
		inlineVideos,
		$mobileFilters,
		$mapCanvas,
		dummyHeight,
		LightboxVideos,
		subIndex = null,
		winH,
		winW,
		winScrollT,
		onScrollGeneralFns = [updateWinScrollT],
		onResizeGeneralFns = [updateWinW, updateWinH],
		canHover = !(matchMedia('(hover: none)').matches),
		historySupport = !!(window.history && history.pushState),
		objectFitSupported = "objectFit" in document.documentElement.style !== false,
		browserDetection = (function detectBrowser() {
			var browser = {};

			if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
				browser.iOS = {};
			}

			if (!browser.iOS && (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification))) { // jshint ignore:line
				browser.safariDesktop = {};
			}

			if (/*@cc_on!@*/false || !!document.documentMode) {
				browser.ie = {};
			}

			if (navigator.userAgent.indexOf('Windows Phone') > -1) {
				browser.windowsPhone = {};
			}

			return browser;
		})();

	function runGeneralFns(fns) {
		fns.forEach(function(fn) {
			fn();
		});
	}

	function updateWinScrollT(val) {
		if (!val) {
			val = $win.scrollTop();
		}

		winScrollT = val;
	}

	function filterReloadMobile(filters) {
		[...filters].forEach(item => {
			item.addEventListener("change", function() {
				var url = $(this).val(); // get selected value
			
				if (url) { // require a URL
					resourcesDropdown(url); // redirect
				}
				
				return false; 
			});
		});
	}

	function resizeTextDummy() {
		var $widetext = $('.page__wide-intro-text.overlap'),
			$dummy = $('.wide-intro__dummy');

		if (!$widetext.length) { return; }

		dummyHeight = $widetext.prop('scrollHeight') / 2;
		$dummy.css('height', dummyHeight + 'px');
	}

	function updateWinH() {
		winH = $win.height();
		let vh = window.innerHeight * 0.01;
  		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	function updateWinW() {
		winW = $win.width();
	}

	function appendOnResize(fn) {
		onResizeGeneralFns.push(fn);
	}

	function initGeneralListeners() {
		$win.on('scroll', function() {
			runGeneralFns(onScrollGeneralFns);
			if(subIndex != null) { subIndex.updateAndMove(); }
		});

		$win.on('resize', function() {
			runGeneralFns(onResizeGeneralFns);
			if(LightboxVideos != null) { LightboxVideos.resizeVideos(); }
			if(subIndex != null) { subIndex.updateAndMove(); }
			resizeTextDummy();
		});

		$body
			.on('click', '.js-resources-filter', resourcesLink)
			.on('click', '.js-load-more', loadMorePosts)
			.on('click', '.js-team-read-more', teamReadMore)
			.on('click', 'a:not(".js-same-page, .uni-touch")', newPageLoad);

		window.addEventListener("popstate", function(e){
			var state = e.state;
			
			if (state !== null) {
				if (state.url !== undefined) {
					pageReloader(state.url, true);
				}
			}
		});

		// Reset dragging flag when a new touchstart is triggered
		document.addEventListener('touchstart', () => {
			dragging = false;
		});

		// Monitor for if the user is scrolling (touchmove) the body
		document.addEventListener('touchmove', () => {
			dragging = true;
		});
	}

	function pageReloadFunctions() {
		pageVideoCount = 0,
	 	$workshop = $('.js-workshop'),
		$video = $('.js-inline-video'),
		slidetest = $('.slide'),
		$mobileFilters = $('.js-mobile-filter'),
		projectTouch = $('.js-touch-hover-state'),
		$mapCanvas = $('.js-map');

		if ($workshop.length) {
			subIndex = new WorkshopHandler($workshop);
		}

		if ($('.slide').length > 1) {
			var slideshow = new Slideshow(document.querySelector('.slideshow'));
		} else if ($('.slide').length == 1) {
			$('.slide').addClass('slide--current');
			$('.slidenav').addClass('hidden');
		}

		if ($mobileFilters.length) {
			filterReloadMobile($mobileFilters);
		}

		if (canHover) {
  			document.body.classList.add('can-hover');
		}

		handleTouchEvents();
		updateWinScrollT();
		updateWinH();
		updateWinW();
		resizeTextDummy();
		handleNavSidebar();

		if ($video.length) {
			inlineVideos = new InlineVideosHandler('.global__page-container');
		}

		if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
			document.body.classList.add('ios');
		}

		pageReveal();
		objectFitPolyfill();
	}

	window.onYouTubeIframeAPIReady = function () {
		handleLightboxVideos();
	}

	var start = window.location.href;
	
	if (historySupport) {
		window.history.replaceState({ url: start }, null, start);
	}

	initGeneralListeners();

	Announcements.init();
	Cookies.init();

	pageReloadFunctions();
});

window.mapLoad = function() {
	var mapSpace = document.querySelectorAll('.js-map');
	
	for(var i = 0; i < mapSpace.length; i++) {
		var latlng = {
			'lat': parseFloat(mapSpace[i].dataset.latitude),
			'lng': parseFloat(mapSpace[i].dataset.longitude)
		};

		var	myOptions = {
			center: latlng,
			zoom: 15,
			fullscreenControl: false,
			panControl: false,
			streetViewControl: false,
			scaleControl: true,
			mapTypeControl: false,
			scrollwheel: false,
			draggable: true,
			styles: [],
			},
			icon = {
				path: "M15,0C6.8,0,0,6.8,0,15c0,8.2,15,33.4,15,33.4s15-25,15-33.4S23.2,0,15,0z M15,20.4c-3,0-5.4-2.4-5.4-5.4S12,9.6,15,9.6s5.4,2.4,5.4,5.4S18,20.4,15,20.4z",
				// path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
				fillColor: "#4633fb",
				anchor: new google.maps.Point(15.0, 48.4),
				fillOpacity: 1,
				strokeWeight: 0,
				scale: 1,
			};

		var	map = new google.maps.Map(mapSpace[i], myOptions);

		var	marker = new google.maps.Marker({
			position: latlng,
			map: map,
			draggable: false,
			icon: icon,
		});
	}
}
